import moment from 'moment-timezone'
import _round from 'lodash.round'
import { formatCurrency, formatDate, formatTime } from '@smarttransit/common'
import { addParamsToLocation } from '@smarttransit/common-client'
import { addAlert, isAuthorized } from '../../../../utilities/helpers'

import {
  getPayoutReport,
  getTransportationUserTransactionLog, markAsPaidToOwner,
  payOwnerAndCheckUntilComplete
} from '../../../../services/transportation-user-transaction-logs-service'

import { findTransportationOwnerUsers } from '../../../../services/transportation-owner-users-service'
import { getUser } from '../../../../services/users-service'

export default {
  name: 'transportation-accounts-payouts-payout',
  props: {
    signedInUser: Object,
    selectedAccount: Object,
    agencies: Array
  },
  data () {
    return {
      apiInProgress: false,
      dateFrom: null,
      dateFromMenu: null,
      dateTo: null,
      dateToMenu: null,
      currentPayout: null,
      report: null,
      reportError: '',
      agencyId: 'STRANSITGHA',
      momoAccountId: null,
      transportationUserMomoAccounts: [],
      markPaidApiInProgress: false
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      if (this.$route.query.agencyId) {
        this.agencyId = this.$route.query.agencyId
      }

      if (this.$route.params.payoutId && this.$route.params.payoutId !== '0') {
        this.getCurrentPayout(this.$route.params.payoutId)
      } else {
        if (this.$route.query.dateFrom) {
          this.dateFrom = this.$route.query.dateFrom
        }

        if (this.$route.query.dateFrom && this.$route.query.dateTo) {
          this.dateTo = this.$route.query.dateTo
        }

        if (this.$route.query.momoAccountId) {
          this.momoAccountId = this.$route.query.momoAccountId
        }

        const { transportationMoneyAccounts, transportationMomoAccounts } = await this.getTransportationOwnerMomoAndMoneyAccounts()
        const moneyAccountLogos = this.$store.getters.getMoneyAccountLogos

        if (transportationMomoAccounts.length || transportationMoneyAccounts.length) {
          this.transportationUserMomoAccounts = transportationMomoAccounts.map((o) => {
            return { text: o.transportationUserIdentifier.phoneNumber, metadata: { src: moneyAccountLogos ? moneyAccountLogos[o.moneyAccountTypeId] : null, moneyAccountTypeId: o.moneyAccountTypeId }, value: o.id }
          })

          if (this.transportationUserMomoAccounts.length === 1) {
            this.momoAccountId = this.transportationUserMomoAccounts[0].value
          }

          // generate report based on dates provided
          if (this.dateFrom && this.agencyId) {
            this.getPendingPayoutReport()
          }
        } else {
          addAlert({
            message: `The current transportation account's owner was not found or has no money account types registered`,
            type: 'error'
          })
        }
      }
    })
  },
  watch: {
    dateFrom (val) {
      this.lastParams = addParamsToLocation({ dateFrom: val }, this.$route, this.lastParams)
    },
    dateTo (val) {
      this.lastParams = addParamsToLocation({ dateTo: val }, this.$route, this.lastParams)
    },
    momoAccountId (val) {
      this.lastParams = addParamsToLocation({ momoAccountId: val }, this.$route, this.lastParams)
    }
  },
  computed: {
    computedDateFromFormatted () {
      return this.dateFrom ? formatDate(this.dateFrom) : ''
    },
    computedDateToFormatted () {
      return this.dateTo ? formatDate(this.dateTo) : ''
    },
    computedDateFromMax () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null

      if (toDateAsMoment && (!fromDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return toDateAsMoment.subtract(1, 'day').format('Y-MM-DD')
      }

      return ''
    },
    computedDateToMin () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null

      if (fromDateAsMoment && (!toDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return fromDateAsMoment.add(1, 'day').format('Y-MM-DD')
      }

      return ''
    },
    isSuperAdmin () {
      return isAuthorized(this.$props.signedInUser, 'superadmin')
    },
    currentSelectedAccount () {
      return this.$props.selectedAccount
    },
    currentAgencies () {
      return this.$props.agencies
    },
    currentMomoAccountLabel () {
      if (this.transportationUserMomoAccounts.length && this.momoAccountId) {
        return this.transportationUserMomoAccounts.find(o => o.value === this.momoAccountId).text
      }
      return ''
    }
  },
  methods: {
    async getTransportationOwnerMomoAndMoneyAccounts () {
      // get the payout account types for this owner
      const transportationOwnerUser = await findTransportationOwnerUsers({
        where: { transportationOwnerId: this.$route.params.accountId, roles: { like: '%OWNER%' }, verified: true },
        include: { transportationUserIdentifiers: 'transportationUserMomoAccounts' }
      })

      // populate the list of momo accounts and any other money accounts
      let transportationMoneyAccounts = []
      let transportationMomoAccounts = []

      if (transportationOwnerUser.length) {
        transportationOwnerUser[0].transportationUserIdentifiers.forEach((identifier) => {
          identifier.transportationUserMomoAccounts.forEach(o => {
            o.transportationUserIdentifier = identifier
          })
          transportationMomoAccounts = transportationMomoAccounts.concat(identifier.transportationUserMomoAccounts || [])
        })
      }

      return { transportationMomoAccounts, transportationMoneyAccounts }
    },
    async getCurrentPayout (id) {
      try {
        this.apiInProgress = true
        this.currentPayout = await getTransportationUserTransactionLog({ id, filter: { include: { transportationUserMomoAccount: 'transportationUserIdentifier' } } })

        this.report = await this.marshallReport({
          [this.currentPayout.logMetadata.agencyId]: {
            agencyId: this.currentPayout.logMetadata.agencyId,
            fee: this.currentPayout.logMetadata.feePercentage,
            grossPayout: this.currentPayout.logMetadata.grossPayout,
            feeDeduction: this.currentPayout.logMetadata.feeDeduction,
            payout: this.currentPayout.amount,
            totalTrips: this.currentPayout.logMetadata.totalTrips,
            currencySymbol: this.currentPayout.logMetadata.currencySymbol,
            dateStart: this.currentPayout.logMetadata.dateStart,
            dateEnd: this.currentPayout.logMetadata.dateEnd,
            byStUserId: this.currentPayout.logMetadata.byStUserId
          }
        })

        this.apiInProgress = false
      } catch (err) {
        this.apiInProgress = false
      }
    },
    async marshallReport (report) {
      const users = {}

      for (const key in report) {
        if (report[key]) {
          report[key].agencyLabel = this.$props.agencies.find(o => o.value === report[key].agencyId).text
          report[key].grossPayoutLabel = `${report[key].currencySymbol}${formatCurrency(report[key].grossPayout)}`
          report[key].payoutLabel = `${report[key].currencySymbol}${formatCurrency(report[key].payout)}`
          report[key].feeLabel = `${_round(report[key].fee * 100, 2)}%`
          report[key].feeDeductionLabel = `${report[key].currencySymbol}${formatCurrency(report[key].feeDeduction)}`
          report[key].dateStartLabel = report[key].dateStart ? formatDate(report[key].dateStart) : 'All pending payouts'
          report[key].dateEndLabel = report[key].dateEnd && formatDate(report[key].dateEnd) !== formatDate(report[key].dateStart) ? formatDate(report[key].dateEnd) : ''

          if (this.currentPayout) {
            report[key].dateUpdatedLabel = formatTime(this.currentPayout.dateUpdated) + ' ' + formatDate(this.currentPayout.dateUpdated)
          }

          if (report[key].byStUserId) {
            try {
              const user = users[report[key].byStUserId] || await getUser({ id: report[key].byStUserId, isDeleted: true })
              report[key].byStUserLabel = [user.firstName, user.lastName].filter(Boolean).join(' ') || user.email
              users[report[key].byStUserId] = user
            } catch (err) {
              addAlert({ message: err, type: 'error' })
              report[key].byStUserLabel = `ID: ${report[key].byStUserId}`
            }
          }
        }
      }

      return report
    },
    async markAsPaid () {
      const promptResult = prompt('This will mark all tickets under this agency as paid without any system payouts.\n' +
        'This is useful when payout has been rendered outside the system.\n' +
        'Please enter "paid" to confirm marking the set period as paid')

      if (promptResult === 'paid') {
        try {
          this.markPaidApiInProgress = true

          const confirmedTransaction = await markAsPaidToOwner({
            transportationUserMomoAccountId: this.momoAccountId,
            dateStart: this.dateFrom,
            dateEnd: this.dateTo ? moment(this.dateTo).endOf('day').valueOf() : null,
            agencyId: this.agencyId
          })

          addAlert({
            message: `Successfully marked as paid`,
            type: 'success',
            transient: true
          })

          this.$router.push({
            name: 'transportation-accounts-payouts-payout',
            params: { payoutId: confirmedTransaction.id }
          })
        } catch (err) {
          this.reportError = `Error in marking as paid: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
        } finally {
          this.markPaidApiInProgress = false
        }
      }
    },
    async requestPayout () {
      if (confirm('Please confirm making a payout to: ' + this.currentSelectedAccount.companyName + ' - ' + this.currentMomoAccountLabel)) {
        try {
          this.apiInProgress = true
          const confirmedTransaction = await payOwnerAndCheckUntilComplete({
            transportationUserMomoAccountId: this.momoAccountId,
            dateStart: this.dateFrom,
            dateEnd: this.dateTo ? moment(this.dateTo).endOf('day').valueOf() : null,
            agencyId: this.agencyId
          })
          this.apiInProgress = false
          addAlert({
            message: `Successfully paid owner`,
            type: 'success',
            transient: true
          })
          this.$router.push({
            name: 'transportation-accounts-payouts-payout',
            params: { payoutId: confirmedTransaction.id }
          })
        } catch (err) {
          this.apiInProgress = false
          this.reportError = `Error in payout: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
        }
      }
    },
    async getPendingPayoutReport () {
      try {
        this.reportError = ''
        if (this.momoAccountId && this.agencyId) {
          this.apiInProgress = true
          const result = await getPayoutReport({
            transportationUserMomoAccountId: this.momoAccountId,
            dateStart: this.dateFrom,
            dateEnd: this.dateTo ? moment(this.dateTo).endOf('day').valueOf() : null,
            agencyId: this.agencyId
          })
          this.apiInProgress = false
          this.report = await this.marshallReport(result.report)
          if (!this.report) {
            this.reportError = 'Based on the options, no unpaid purchases were found to generate a report'
          }
        } else {
          const fields = [
            // { key: '"Date from"', value: this.dateFrom },
            { key: '"Agency ID"', value: this.agencyId },
            { key: '"Mobile money account"', value: this.momoAccountId }]
            .filter(o => !o.value)
            .map(o => o.key).join(', ')
          addAlert({
            message: `The following required field(s) are missing: ${fields}`,
            type: 'error'
          })
        }
      } catch (err) {
        this.apiInProgress = false
        this.reportError = `Error in payout report: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
        // this.$store.commit(C_ADD_SITE_ALERT, {
        //   message: `Error in payout report: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
        //   type: 'error'
        // })
      }
    },
    onFilterByAgency (val) {
      this.selectedAgency = val
    }
  },
  beforeDestroy () {
    if (this.$router.currentRoute.name !== 'transportation-accounts-payouts-payout') {
      if (this.lastParams && Object.keys(this.lastParams).length) {
        addParamsToLocation({ dateFrom: null, dateTo: null, agencyId: null, momoAccountId: null }, this.$route, this.lastParams)
      }
    }
  }
}
