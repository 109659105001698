<template>
  <div class="transportation-accounts-payouts-schedule">
    <div v-if="$router.currentRoute.name === 'transportation-accounts-payouts-schedule'" class="mx-2" style="max-width: 900px">
      <div class="layout justify-end align-center wrap">
        <v-btn flat color="warning" class="ml-2" @click="cancel()">Cancel</v-btn>
        <v-btn v-if="scheduledPayouts.length && scheduledPayouts[0].dateArchived" :disabled="isAnyApiInProgress()" :loading="disableApiInProgress" class="ml-2" @click="enableDisableSchedule('enable')"><v-icon small>fal fa-undo</v-icon> &nbsp; Enable</v-btn>
        <v-btn v-if="scheduledPayouts.length && !scheduledPayouts[0].dateArchived" :disabled="isAnyApiInProgress()" :loading="disableApiInProgress" class="ml-2" @click="enableDisableSchedule('disable')"><v-icon small>fal fa-ban</v-icon> &nbsp; Disable</v-btn>
        <v-btn v-if="isCreatingScheduledPayout" class="ml-2" color="primary" :disabled="isAnyApiInProgress()" :loading="saveApiInProgress" @click="saveScheduledPayout()">Create Scheduled Payout</v-btn>
        <v-btn v-if="!isCreatingScheduledPayout" :disabled="!isFormDirty() || isAnyApiInProgress()" :loading="saveApiInProgress" class="ml-2" color="primary" @click="saveScheduledPayout()">Update</v-btn>
        <v-btn fab small class="ml-2" :disabled="isAnyApiInProgress()" :loading="apiInProgress" @click="refresh()"><v-icon small>fal fa-sync</v-icon></v-btn>
      </div>
      <v-layout row wrap>
        <v-flex sm6 pa-2>
          <div class="layout row justify-start align-center mb-2">
            <div>
              <v-tooltip top max-width="400">
                <template v-slot:activator="{ on }">
                  <v-btn flat icon><v-icon v-on="on">fa fa-info-circle</v-icon></v-btn>
                </template>
                <span>
                  The timezone for all the below date and time settings.<br/>
                  Type in some keywords to filter and find your timezone
                </span>
              </v-tooltip>
            </div>
            <div v-if="scheduledPayoutToSubmit.timezoneSelected !== localTimezone">
              <v-btn flat icon @click="resetTimezone()"><v-icon small>fal fa-undo</v-icon></v-btn>
            </div>
            <div style="flex: 1">
              <timezone-selector :child-props="{ outline: true, label: 'Scheduler timezone' }" v-model="scheduledPayoutToSubmit.timezoneSelected" />
            </div>
          </div>
          <div class="layout row justify-start align-center mb-2">
            <div>
              <v-tooltip top max-width="400">
                <template v-slot:activator="{ on }">
                  <v-btn flat icon><v-icon v-on="on">fa fa-info-circle</v-icon></v-btn>
                </template>
                <span>
                  Select a frequency by which payouts will occur.<br />
                  (Bi-weekly payments will never occur past the 28th day of any month)
                </span>
              </v-tooltip>
            </div>
            <div style="flex: 1">
              <v-select
                outline
                :items="frequencies"
                label="Frequency"
                v-model="scheduledPayoutToSubmit.frequency"
              />
            </div>
          </div>
          <div class="mb-2">
            <v-layout row wrap>
              <v-flex :sm4="scheduledPayoutToSubmit.frequency !== 'daily'" pr-2>
                <time-field v-model="timeValue" :other-props="{ label: 'Time' }" />
              </v-flex>
              <v-flex v-if="scheduledPayoutToSubmit.frequency !== 'daily'" sm8>
                <div class="layout row justify-start align-center">
                  <div v-if="scheduledPayoutToSubmit.frequency === 'bi-weekly' || scheduledPayoutToSubmit.frequency === 'monthly'">
                    <v-tooltip top max-width="400">
                      <template v-slot:activator="{ on }">
                        <v-btn flat icon><v-icon v-on="on">fa fa-info-circle</v-icon></v-btn>
                      </template>
                      <span v-if="scheduledPayoutToSubmit.frequency === 'bi-weekly'">
                        With bi-weekly payments (twice a month), when you select the date in the first week, the date for the second week is obtained automatically. For eg. selecting the 8th of the month will automatically select the 22nd (2 weeks later). The latest date you can select for the first week is the 14th.
                      </span>
                      <span v-if="scheduledPayoutToSubmit.frequency === 'monthly'">
                        With monthly payments select one date in the month to receive a payout. The option: "Last day of the month" means payout will be given on that month's last day (eg. 28th, 30th or 31st)
                      </span>
                    </v-tooltip>
                  </div>
                  <div style="flex: 1">
                    <v-select
                      outline
                      v-if="scheduledPayoutToSubmit.frequency === 'weekly'"
                      :items="weekdays"
                      label="Day of the week"
                      v-model="scheduledPayoutToSubmit.weekday"
                    />
                    <v-select
                      outline
                      persistent-hint
                      :hint="biWeeklyHint"
                      v-if="scheduledPayoutToSubmit.frequency === 'bi-weekly'"
                      :items="biWeeklyDates"
                      label="Payout date of the first week"
                      v-model="scheduledPayoutToSubmit.biWeeklyDate"
                    />
                    <v-select
                      outline
                      v-if="scheduledPayoutToSubmit.frequency === 'monthly'"
                      :items="monthlyDates"
                      label="Day in the month"
                      v-model="scheduledPayoutToSubmit.monthlyDate"
                    />
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </div>
          <div class="mb-2">
            <v-layout row wrap>
              <v-flex md6 pr-2>
                <v-select
                  outline
                  v-model="scheduledPayoutToSubmit.agencyId"
                  :items="currentAgencies"
                  label="Agency"
                />
              </v-flex>
              <v-flex md6>
                <v-select
                  outline
                  v-model="scheduledPayoutToSubmit.momoAccountId"
                  :items="transportationUserMomoAccounts"
                  label="Mobile money accounts"
                >
                  <template slot="item" slot-scope="{ item }">
                    <div class="layout row justify-start align-center">
                      <div>{{item.text}}</div>
                      <div v-if="item.metadata.moneyAccountTypeId" class="ml-2">
                        <img v-if="item.metadata.src" :src="item.metadata.src" style="height: 30px;" :alt="item.metadata.moneyAccountTypeId" />
                        <small v-if="!item.metadata.src">({{item.metadata.moneyAccountTypeId}})</small>
                      </div>
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ item }">
                    <div class="layout row justify-start align-center">
                      <div>{{item.text}}</div>
                      <div v-if="item.metadata.moneyAccountTypeId" class="ml-2">
                        <img v-if="item.metadata.src" :src="item.metadata.src" style="height: 30px;" :alt="item.metadata.moneyAccountTypeId" />
                        <small v-if="!item.metadata.src">({{item.metadata.moneyAccountTypeId}})</small>
                      </div>
                    </div>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="isCreatingScheduledPayout">
            <v-menu
              v-model="scheduledJobStartDateMenu"
              :close-on-content-click="false"
              full-width
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  prepend-icon="fal fa-calendar"
                  :value="computedScheduledJobStartFormatted"
                  @click:clear="scheduledJobStartDate = null"
                  clearable
                  label="Start this schedule on a particular date"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                no-title
                :min="scheduledJobStartDateMinimum"
                :width="373"
                v-model="scheduledJobStartDate"
                @change="scheduledJobStartDateMenu = false"
              />
            </v-menu>
          </div>
        </v-flex>
        <v-flex sm6 pa-2>
          <v-alert v-if="scheduledPayouts && scheduledPayouts[0] && scheduledPayouts[0].dateArchived" :value="true" icon="warning" color="warning" class="mb-2">
            This scheduled payout is disabled. It will not run according to the schedule.
            Click the "enable" button to enable.
          </v-alert>
          <v-alert v-if="!isCreatingScheduledPayout" :value="true" icon="watch" class="mb-2" color="info">
            <div v-html="recentPayoutActivityHtml" />
          </v-alert>
          <v-alert v-if="scheduledPayoutHtml" :value="true" icon="info" class="mb-2" color="info">
            <div v-html="scheduledPayoutHtml" />
          </v-alert>
        </v-flex>
      </v-layout>
      <div class="mb-2 text-xs-right" v-if="!isCreatingScheduledPayout">
        <v-btn :disabled="isAnyApiInProgress()" :loading="deleteApiInProgress" @click="deleteScheduledPayout()"><v-icon small color="error">fa fa-trash-alt</v-icon> &nbsp; Delete</v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
